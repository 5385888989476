import { ICountries, IRegion } from "@/models/Metadata";
import { ErrorResponse } from "@/services/axios/error";
import { MetadataAPI } from "@/services/metadata";
import store from "@/store";
import { mergeCountriesData } from "@/utils/helpers/buildDataFormat";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "MetadataBOMSModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class MetadataBOMSModule extends VuexModule {
  private errorGetFacilities = {} as ErrorResponse;
  private allCountries = [] as ICountries[];

  get allCountriesGetter() {
    return this.allCountries;
  }
  @Mutation
  getAllCountrySuccess(data: any) {
    this.errorGetFacilities = {} as ErrorResponse;
    this.allCountries = mergeCountriesData(data);
  }
  @Mutation
  getAllCountryFailure(error: ErrorResponse) {
    this.errorGetFacilities = error;
  }
  @Action
  async getAllCountryAction() {
    const MetadataService = new MetadataAPI();
    const { data, error } = await MetadataService.getAllCountry();
    if (error) {
      this.getAllCountryFailure(error);
      return;
    }
    this.getAllCountrySuccess(data);
  }
}

export default getModule(MetadataBOMSModule);
